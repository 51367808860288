#ModalCard {
    position: relative;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    
}

#ModalCard::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 25px;
    padding: 5px;
    background: linear-gradient(180deg, var(--primaryBlue), var(--primaryGreen));
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

