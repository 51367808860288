.header {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
}

.start {
    font-size: 35px;
    font-family: 'Allerta Stencil', sans-serif;
}

.Searchbar {
    background: linear-gradient(rgb(250, 250, 250), rgb(250, 250, 250)) padding-box,
        linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%) border-box;
    border-radius: 20px;
    border: 3px solid transparent;

    font-family: 'Allerta', sans-serif;
    font-size: 30px;
    padding: 5px;
    padding-left: 15px;

}

.DrawTitle {
    font-family: 'Allerta Stencil', sans-serif;
}

#Searchbar {
    background: linear-gradient(rgb(250, 250, 250), rgb(250, 250, 250));
    border: 0px solid transparent;
    font-family: 'Allerta', sans-serif;
    font-size: 30px;
    padding: 5px;
    padding-left: 15px;
}

.end {
    font-family: 'Allerta', sans-serif;
    font-size: 25px;
}

a {
    text-decoration: none;
    color: inherit
}

input:focus {
    outline: none;
}

@media screen and (max-width: 899px) {
    #Searchbar {
        width: 270px;
    }
}

@media screen and (max-width: 735px) {
    .header {
        flex-direction: column;
    }
}

@media screen and (max-width: 403px) {
    #Searchbar {
        width: 170px;
        font-size: 20px;
        padding-top: 0px;
    }

    .Searchbar {
        padding-top: 0px;
    }

    .header {
        flex-direction: column;
    }

    a {
        font-size: inherit;
    }

    .Menubutton {
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 337px) {
    a {
        font-size: inherit;
    }
    .Menubutton {
        padding-bottom: 12px;
    }
}

@media screen and (max-width: 302px) {
    a {
        font-size: inherit;
    }

    .Menubutton {
        margin-right: 0px;
        padding-bottom: 5px;
    }

    #Searchbar {
        width: 150px;
        
    }
}