.center{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  img{
    background: linear-gradient(rgb(250, 250, 250), rgb(250, 250, 250)) padding-box,
          linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%) border-box;
      border-radius: 20px;
      border: 5px solid transparent;
  }