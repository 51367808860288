/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allerta&family=Allerta+Stencil&display=swap');

:root {
  --primaryBlue: #0500ff;
  --primaryGreen: #00ff38;
  --primaryRed: #ff0f00;
}


.Home {
  display: flex;
  justify-content: center;
}

.Message {
  margin-top: 10%;
  text-align: center;
}

.Examples {
  margin-top: 20%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.UseCase {
  display: flex;
  justify-content: center;
  text-align: center;
}

#test {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;

}

#test::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  padding: 5px;
  background: linear-gradient(180deg, var(--primaryBlue), var(--primaryGreen));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}